<template>
  <j-abm
    name="store"
    avatar="shop"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }">
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h5>{{ item.name }}</h5>
              <j-label icon="number">
                {{ item.id }}
              </j-label>
            </div>
            <div class="col-12 col-md-6">
              <j-label icon="shopping-cart">
                {{ $t(`enum.collect.${item.collect}`) }}
              </j-label>
              <j-label icon="environment">
                <j-address :value="item.address" />
              </j-label>
              <j-label :icon="item.main ? 'star' : 'shop'">
                {{ $t(`msg.store.main.${item.main}`) }}
              </j-label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import JAbm from '@/views/shared/abm/search'
import JLabel from '@/views/shared/labels/text'
import JAddress from '@/views/shared/labels/address'
export default {
  components: {
    JAbm,
    JLabel,
    JAddress,
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchAll: page => lms.account.fetchStores(this.account, page),
      }
    },
  },
}
</script>
