var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"store","avatar":"shop","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: false,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('j-label',{attrs:{"icon":"number"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('j-label',{attrs:{"icon":"shopping-cart"}},[_vm._v(" "+_vm._s(_vm.$t(("enum.collect." + (item.collect))))+" ")]),_c('j-label',{attrs:{"icon":"environment"}},[_c('j-address',{attrs:{"value":item.address}})],1),_c('j-label',{attrs:{"icon":item.main ? 'star' : 'shop'}},[_vm._v(" "+_vm._s(_vm.$t(("msg.store.main." + (item.main))))+" ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }